import * as Yup from 'yup'

export default Yup.object().shape({
  file_name: Yup.string()
    .required('Nome do arquivo é obrigatório')
    .max(255, 'Nome do arquivo muito longo'),
  description: Yup.string()
    .nullable(),
  url: Yup.string()
    .required('URL é obrigatória')
    .url('URL inválida')
    .max(2048, 'URL muito longa'),
  file_size: Yup.number()
    .required('Tamanho do arquivo é obrigatório')
    .min(0.01, 'Tamanho do arquivo deve ser maior que 0') // Agora requer um valor mínimo de 0.01
    .test(
      'isGreaterThanZero',
      'Tamanho do arquivo deve ser maior que 0',
      (value) => value !== null && value > 0
    ),
  server_type: Yup.string()
    .required('Tipo de servidor é obrigatório')
    .max(50, 'Tipo de servidor muito longo')
})