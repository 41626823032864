import React from "react"
import { useField } from "formik"
import { InputText } from "primereact/inputtext"
import ErrorMessage from "./ErrorMessage"
import { KeyFilterType } from "primereact/keyfilter"

interface IFormikInput {
	label: string
	name: string
	placeholder?: string
	style?: object
	className?: string
	type?: string
	onChanged?: (e: any) => void
	onInput?: (e: any) => void
	value?: string
	error?: string
	defaultValue?: string
	title?: string
	min?: string
	max?: string
	disabled?: boolean
	readOnly?: boolean
	labelClass?: string
	maxLength?: number
	keyfilter?: KeyFilterType | undefined
	autoComplete?: string
	step?: number
}

const FormikInput: React.FC<IFormikInput> = ({
	name,
	label,
	placeholder,
	style,
	type = "text",
	onChanged,
	onInput,
	value,
	error,
	defaultValue,
	disabled = false,
	title,
	min,
	max,
	readOnly,
	className = "w-full",
	labelClass,
	maxLength,
	keyfilter,
	autoComplete,
	step,
}: IFormikInput) => {
	const [field, meta] = useField(name)

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		field.onChange(e)
		if (onChanged) {
			onChanged(e)
		}
	}

	const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
		field.onChange(e)
		if (onInput) {
			onInput(e)
		}
	}

	const inputValue = value !== undefined ? value : field.value

	return (
		<div className={`field ${className}`} style={style}>
			<label
				htmlFor={name}
				className={`font-sans Roboto font-normal max-sm:w-64 mb-0 ${labelClass}`}
			>
				{label}
			</label>
			<InputText
				id={name}
				{...field}
				className={`p-inputtext w-full ${meta.touched && meta.error ? "p-invalid" : ""}`}
				placeholder={placeholder}
				type={type}
				onChange={handleInputChange}
				onInput={handleInput}
				value={inputValue}
				defaultValue={defaultValue}
				title={title}
				min={min}
				max={max}
				disabled={disabled}
				readOnly={readOnly}
				maxLength={maxLength}
				keyfilter={keyfilter}
				autoComplete={autoComplete}
				step={step}
			/>
			{meta.error && meta.touched && <ErrorMessage message={meta.error} />}
			{error && <ErrorMessage message={error} />}
		</div>
	)
}

export default FormikInput
