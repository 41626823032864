import { useState } from "react"
import { Button } from "primereact/button"
import { Dialog } from "primereact/dialog"
import Loading from "../../../components/Loading"
import http from "../../../plugins/http"
import { BiForestaFile } from "../types/BIFlorestaFilesType"
import { Trash } from "@styled-icons/boxicons-regular"

interface ModalDeleteFileProps {
	file: BiForestaFile
	onDelete?: () => void
	className?: string
}

function ModalDeleteFile({ file, onDelete, className }: ModalDeleteFileProps) {
	const [modal, setModal] = useState(false)
	const [loading, setLoading] = useState(false)

	const toggle = () => setModal(!modal)

	async function deleteFile() {
		try {
			setLoading(true)
			await http.delete(`/v1/bi-floresta-files/${file.id}`)
			toggle()
			if (onDelete) onDelete()
		} catch (error) {
			console.error(error)
		} finally {
			setLoading(false)
		}
	}

	return (
		<div className={className}>
			<Trash
				onClick={toggle}
				size={25}
				color='#F91B1B'
				className='cursor-pointer'
			/>
			<Dialog
				header='Confirmar exclusão'
				onHide={toggle}
				visible={modal}
				style={{ width: "30vw" }}
			>
				<div className='p-6'>
					<p className='text-lg mb-6'>
						Deseja realmente excluir o arquivo "{file.file_name}"?
					</p>

					<div className='flex justify-end gap-x-4'>
						<Button onClick={toggle} disabled={loading} severity='secondary'>
							Cancelar
						</Button>
						<Button onClick={deleteFile} disabled={loading} severity='danger'>
							{loading && <Loading />}
							Confirmar exclusão
						</Button>
					</div>
				</div>
			</Dialog>
		</div>
	)
}

export default ModalDeleteFile
