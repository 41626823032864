export type dateBR = {
  date: String | Date | undefined
  options?: {
    hour: boolean
  }
}

export const dateBR = (props: dateBR | undefined) => {
  if (props === undefined || props.date === undefined || props.date === null) {
    return "Sem data"
  }

  const format = Intl.DateTimeFormat("pt-BR", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: props.options?.hour ? "numeric" : undefined,
    minute: props.options?.hour ? "numeric" : undefined,
    second: props.options?.hour ? "numeric" : undefined,
    timeZone: "America/Sao_Paulo",
  })

  try {
    if (typeof props.date === "string") {
      return format.format(new Date(props.date))
    }
    return format.format(props.date as Date)
  } catch (error) {
    console.error("Error formatting date:", error)
    return "Data inválida"
  }
}

export const dateDiff = (date1: string | Date | undefined, date2: string | Date | undefined) => {
  if (date1 === undefined || date2 === undefined) {
    return "Sem data"
  }

  if (typeof date1 === "string") date1 = new Date(date1)
  if (typeof date2 === "string") date2 = new Date(date2)
  return Math.floor((Date.UTC(date2.getFullYear(), date2.getMonth(), date2.getDate()) - Date.UTC(date1.getFullYear(), date1.getMonth(), date1.getDate())) / (1000 * 60 * 60 * 24));
}

/**Função utilizada nas tabelas de demandas cadastradas, para visulizar o tempo da demanda */
export function diffDate(date: Date | string | undefined, date2: Date | string | undefined): { diff: string; count: number } | string {
  if (date === undefined || date2 === undefined) {
    return "Sem data"
  }

  if (typeof date === "string") {
    date = new Date(date);
  }
  if (typeof date2 === "string") {
    date2 = new Date(date2);
  }
  const years = date.getFullYear() - date2.getFullYear();
  const months = date.getMonth() - date2.getMonth();
  if (date.getFullYear() === date2.getFullYear() && date.getMonth() === date2.getMonth()) {
    const days = date.getDate() - date2.getDate();
    if (days === 1) {
      return { diff: "dia", count: 1 };
    }
    return { diff: "dias", count: days };
  }
  if (date.getFullYear() === date2.getFullYear()) {
    if (months === 1) {
      return { diff: "mês", count: 1 };
    }
    return { diff: "meses", count: months };
  }
  if (years === 1) {
    return { diff: "ano", count: 1 };
  }
  return { diff: "anos", count: years };
}

export function listOfMonths() {

  const months = [
		{ label: "Janeiro", value: 1 },
		{ label: "Fevereiro", value: 2 },
		{ label: "Março", value: 3 },
		{ label: "Abril", value: 4 },
		{ label: "Maio", value: 5 },
		{ label: "Junho", value: 6 },
		{ label: "Julho", value: 7 },
		{ label: "Agosto", value: 8 },
		{ label: "Setembro", value: 9 },
		{ label: "Outubro", value: 10 },
		{ label: "Novembro", value: 11 },
		{ label: "Dezembro", value: 12 },
	]
  return months
}