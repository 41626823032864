import http from "../plugins/http"

export const fetchDashboadUsers = async () => {
  const data = await http.get(`/v1/reports/dashboard/users`)
  return data
}

export const fetchDashboadUsersByMonth = async () => {
  const data = await http.get(`/v1/reports/dashboard/users-by-month`)
  return data
}

export const fetchDashboadProductsByMonth = async () => {
  const data = await http.get(`/v1/reports/dashboard/products-by-month`)
  return data
}


export const fetchDashboadSales = async () => {
  const data = await http.get(`/v1/reports/dashboard/sales?status=5`)
  return data
}
