import React, { useState } from "react"

interface MenuItem {
	label: string
	icon?: string
	image?: string
	command?: () => void
	items?: MenuItem[]
	className?: string
}

interface MenuCustomProps {
	model: MenuItem[]
	className?: string
}

const MenuItemComponent: React.FC<{
	item: MenuItem
	level?: number
}> = ({ item, level = 0 }) => {
	const [isOpen, setIsOpen] = useState(false)

	const handleClick = () => {
		if (item.items) {
			setIsOpen(!isOpen)
		}
		if (item.command) {
			item.command()
		}
	}

	return (
		<li className={`relative ${level > 0 ? "ml-4" : ""}`}>
			<div
				className={`
                    flex items-center px-6 py-3 my-1 cursor-pointer
                    rounded-lg transition-all duration-200 ease-in-out
                    hover:bg-green-50 hover:shadow-md
                    ${item.className || ""}
                    ${level === 0 ? "mx-2" : "mx-1"}
                    group
                `}
				onClick={handleClick}
			>
				{item.icon && (
					<i
						className={`
                        ${item.icon} mr-3 text-lg
                        group-hover:text-green-600
                        transition-colors duration-200
                        ${item.className?.includes("bg-green-200") ? "text-green-600" : "text-gray-500"}
                    `}
					/>
				)}
				{item.image && (
					<img
						src={item.image}
						alt={item.label}
						className={`
												w-6 h-6 object-cover mr-3
												group-hover:text-green-600
												transition-colors duration-200
												${item.className?.includes("bg-green-200") ? "text-green-600" : "text-gray-500"}
										`}
					/>
				)}
				<span
					className={`
                    flex-grow text-sm font-medium
                    group-hover:text-green-700
                    transition-colors duration-200
                    ${item.className?.includes("bg-green-200") ? "text-green-700" : "text-gray-600"}
                `}
				>
					{item.label}
				</span>
				{item.items && (
					<i
						className={`
                        pi ${isOpen ? "pi-chevron-down" : "pi-chevron-right"}
                        ml-2 text-xs
                        group-hover:text-green-600
                        transition-transform duration-200
                        ${isOpen ? "rotate-0" : "rotate-0"}
                        ${item.className?.includes("bg-green-200") ? "text-green-600" : "text-gray-400"}
                    `}
					/>
				)}
			</div>
			{item.items && (
				<ul
					className={`
                    list-none p-0 m-0
                    overflow-hidden transition-all duration-200 ease-in-out
                    ${isOpen ? "max-h-96 opacity-100" : "max-h-0 opacity-0"}
                `}
				>
					{item.items.map((subItem, index) => (
						<MenuItemComponent key={index} item={subItem} level={level + 1} />
					))}
				</ul>
			)}
		</li>
	)
}

const MenuCustom: React.FC<MenuCustomProps> = ({ model, className }) => {
	return (
		<div className={`py-2 ${className || ""}`}>
			<ul className='list-none p-0 m-0'>
				{model.map((item, index) => (
					<MenuItemComponent key={index} item={item} />
				))}
			</ul>
		</div>
	)
}

export default MenuCustom
