import { Card } from "primereact/card"
import React, { useEffect } from "react"
import {
	fetchDashboadProductsByMonth,
	fetchDashboadSales,
	fetchDashboadUsers,
	fetchDashboadUsersByMonth,
} from "../../api/dashboard"
import Highcharts from "highcharts"
import HighchartsReact from "highcharts-react-official"
import { Dropdown } from "primereact/dropdown"
import HC_exporting from "highcharts/modules/exporting"
import HC_exportData from "highcharts/modules/export-data"
import HighchartsOfflineExporting from "highcharts/modules/offline-exporting"
import HC_accessibility from "highcharts/modules/accessibility"

HC_exporting(Highcharts)
HC_exportData(Highcharts)
HighchartsOfflineExporting(Highcharts)
HC_accessibility(Highcharts)
Highcharts.setOptions({
	lang: {
		viewFullscreen: "Ver em tela cheia",
		exitFullscreen: "Sair da tela cheia",
		printChart: "Imprimir gráfico",
		downloadPNG: "Download PNG",
		downloadJPEG: "Download JPEG",
		downloadPDF: "Download PDF",
		downloadXLS: "Download XLS",
		downloadCSV: "Download CSV",
		downloadSVG: "Download SVG",
		contextButtonTitle: "Menu do gráfico",
		months: [
			"Janeiro",
			"Fevereiro",
			"Março",
			"Abril",
			"Maio",
			"Junho",
			"Julho",
			"Agosto",
			"Setembro",
			"Outubro",
			"Novembro",
			"Dezembro",
		],
		shortMonths: [
			"Jan",
			"Fev",
			"Mar",
			"Abr",
			"Mai",
			"Jun",
			"Jul",
			"Ago",
			"Set",
			"Out",
			"Nov",
			"Dez",
		],
		weekdays: [
			"Domingo",
			"Segunda",
			"Terça",
			"Quarta",
			"Quinta",
			"Sexta",
			"Sábado",
		],
		thousandsSep: ".",
		decimalPoint: ",",
	},
	exporting: {
		buttons: {
			contextButton: {
				menuItems: [
					"printChart",
					"downloadPNG",
					"downloadJPEG",
					"downloadPDF",
					"downloadXLS",
				],
			},
		},
		allowHTML: true,
		printMaxWidth: 780,
		scale: 2,
	},
	accessibility: {
		enabled: true,
		announceNewData: {
			announcementFormatter: function (allSeries, newSeries, newPoint) {
				if (newPoint) {
					return "Novo ponto adicionado ao gráfico"
				}
				return false
			},
		},
	},
})

const MainPanel: React.FC = () => {
	const [dashboardsUsers, setDashboardsUsers] = React.useState<any[]>([])
	const [dashboardsUsersByMonth, setDashboardsUsersByMonth] = React.useState<
		any[]
	>([])
	const [dashboardsSalesByMonth, setDashboardsSalesByMonth] = React.useState<
		any[]
	>([])
	const [dashboardsProductsByMonth, setDashboardsProductsByMonth] =
		React.useState<any>([])
	const [selectedProfile, setSelectedProfile] = React.useState<any>(null)
	const [dashboardsUsersByTypeSelected, setDashboardsUsersByTypeSelected] =
		React.useState<any[]>([])

	const profiles = [
		"Compr./Vend.",
		"Vendedores",
		"Compradores",
		"Administradores",
		"Tem compra",
		"Tem vendas",
		"Tem produtos",
		"Tem demandas",
		"Visitantes (CPF)",
		"Outros perfis",
		"Todos",
		"Vend. (Perfil incompleto)",
	]

	useEffect(() => {
		fetchDashboadUsers().then((data) => {
			if (data.status !== 200) return console.error(data.data)
			setDashboardsUsers(data.data)
		})
		fetchDashboadUsersByMonth().then((data) => {
			if (data.status !== 200) return console.error(data.data)
			setDashboardsUsersByMonth(data.data)
		})
		fetchDashboadProductsByMonth().then((data) => {
			if (data.status !== 200) return console.error(data.data)
			setDashboardsProductsByMonth(data.data)
		})
		fetchDashboadSales().then((data) => {
			if (data.status !== 200) return console.error(data.data)

			const salesByMonth = data.data.reduce((acc: any, item: any) => {
				const month = new Date(item.created_at).getMonth()
				const year = new Date(item.created_at).getFullYear()
				const key = `${month + 1}/${year}`
				if (!acc[key]) acc[key] = 0
				acc[key] += Number(item.total)
				return acc
			}, {})

			setDashboardsSalesByMonth(
				Object.keys(salesByMonth).map((key) => ({
					month: key,
					value: salesByMonth[key],
				})),
			)
		})
	}, [])

	useEffect(() => {
		if (dashboardsUsersByMonth.length > 0) {
			selectProfileOfUsers("Todos")
		}
	}, [dashboardsUsersByMonth])

	const selectProfileOfUsers = (profile: string) => {
		const users = []
		for (const user of dashboardsUsersByMonth) {
			const filtered = user.data.filter((x: any) => x.name === profile)[0]
			users.push({
				name: filtered.name,
				month: user.month,
				value: filtered.value,
			})
		}
		setSelectedProfile(profile)
		setDashboardsUsersByTypeSelected(users)
	}

	const pieChartOptions = {
		chart: {
			type: "pie",
		},
		title: {
			text: "Usuários por tipo",
		},
		exporting: {
			filename: "usuarios_por_tipo",
		},
		plotOptions: {
			pie: {
				allowPointSelect: true,
				cursor: "pointer",
				dataLabels: {
					enabled: true,
					format: "<b>{point.name}</b>: {point.percentage:.1f}% ({point.y})",
					distance: 20,
				},
				showInLegend: true,
			},
		},
		series: [
			{
				name: "Quantidade",
				data: dashboardsUsers.map((item: any) => ({
					name: item.name,
					y: item.value,
				})),
			},
		],
		lang: {
			exportData: {
				categoryHeader: "Tipo de usuário",
				valueHeader: "Quantidade",
			},
		},
	}

	const salesChartOptions = {
		chart: {
			type: "column",
		},
		title: {
			text: "Vendas por mês",
		},
		exporting: {
			filename: "vendas_por_mes",
		},
		xAxis: {
			categories: dashboardsSalesByMonth.map((item) => item.month),
		},
		yAxis: {
			title: {
				text: "Valor das Vendas (R$)",
			},
		},
		plotOptions: {
			column: {
				dataLabels: {
					enabled: true,
					format: "R$ {point.y:.2f}",
				},
			},
		},
		tooltip: {
			pointFormat: "R$ {point.y:.2f}",
		},
		series: [
			{
				name: "Valor Total (R$)",
				data: dashboardsSalesByMonth.map((item) => ({
					name: item.month,
					y: parseFloat(item.value),
				})),
			},
		],
		lang: {
			exportData: {
				categoryHeader: "Data",
				valueHeader: "Valor Total (R$)",
			},
		},
	}

	const usersChartOptions = {
		chart: {
			type: "column",
		},
		title: {
			text: "Usuários cadastrados por mês",
		},
		exporting: {
			filename: "usuarios_cadastrados_mes",
		},
		xAxis: {
			categories: dashboardsUsersByTypeSelected.map((item) => {
				const [ano, mes] = item.month.split("-")
				return `${mes}/${ano}`
			}),
		},
		yAxis: {
			title: {
				text: "Total de usuários",
			},
		},
		plotOptions: {
			column: {
				dataLabels: {
					enabled: true,
					format: "{point.y}",
				},
			},
		},
		tooltip: {
			pointFormat: "{point.y}",
		},
		series: [
			{
				name: "Total de usuários",
				data: dashboardsUsersByTypeSelected.map((item) => item.value),
			},
		],
		lang: {
			exportData: {
				categoryHeader: "Data",
				valueHeader: "Total de usuários",
			},
		},
	}

	// Configuração do gráfico de produtos
	const productsChartOptions = {
		chart: {
			type: "column",
		},
		title: {
			text: "Produtos cadastrados por mês",
		},
		exporting: {
			filename: "produtos_cadastrados_mes",
		},
		xAxis: {
			categories: dashboardsProductsByMonth.map((item: any) => {
				const [ano, mes] = item.month.split("-")
				return `${mes}/${ano}`
			}),
		},
		yAxis: {
			title: {
				text: "Total de produtos",
			},
		},
		plotOptions: {
			column: {
				dataLabels: {
					enabled: true,
					format: "{point.y}",
				},
			},
		},
		tooltip: {
			pointFormat: "{point.y}",
		},
		series: [
			{
				name: "Total de produtos",
				data: dashboardsProductsByMonth.map((item: any) => item.total),
			},
		],
		lang: {
			exportData: {
				categoryHeader: "Data",
				valueHeader: "Total de produtos",
			},
		},
	}

	return (
		<div className='w-full grid grid-cols-2 xl:grid-cols-4 gap-2'>
			<Card className='col-span-2'>
				<div
					className='chart-container'
					style={{ position: "relative", zIndex: 1 }}
				>
					<HighchartsReact highcharts={Highcharts} options={pieChartOptions} />
				</div>
			</Card>
			<Card className='col-span-2'>
				<HighchartsReact highcharts={Highcharts} options={salesChartOptions} />
			</Card>
			<Card className='col-span-2'>
				<Dropdown
					title='Selecione um perfil'
					value={selectedProfile}
					options={profiles}
					onChange={(e) => selectProfileOfUsers(e.value)}
					placeholder='Selecione um perfil'
				/>
				<HighchartsReact highcharts={Highcharts} options={usersChartOptions} />
			</Card>
			<Card className='col-span-2'>
				<HighchartsReact
					highcharts={Highcharts}
					options={productsChartOptions}
				/>
			</Card>
		</div>
	)
}

export default MainPanel
