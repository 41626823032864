import { Form, Formik, useFormikContext } from "formik"
import { useState } from "react"
import { Button } from "primereact/button"
import { Dialog } from "primereact/dialog"
import { Message } from "primereact/message"
import FormikInput from "../../../components/FormikInput"
import Loading from "../../../components/Loading"
import http from "../../../plugins/http"
import Schema from "../validation/validations"

function FormButtons({
	toggle,
	loading,
}: { toggle: () => void; loading: boolean }) {
	const { submitForm } = useFormikContext()
	return (
		<div className='w-full flex justify-end p-4 gap-x-4'>
			<Button onClick={toggle} disabled={loading} severity='danger'>
				Cancelar
			</Button>
			<Button
				type='submit'
				onClick={submitForm}
				disabled={loading}
				severity='success'
			>
				{loading && <Loading />}
				Incluir
			</Button>
		</div>
	)
}

interface ModalRegisterFileProps {
	onSuccess?: () => void
	args?: any
}

function ModalRegisterFile({ onSuccess, args }: ModalRegisterFileProps) {
	const [modal, setModal] = useState(false)
	const [loading, setLoading] = useState(false)
	const [error, setError] = useState("")

	const toggle = () => setModal(!modal)

	const initialValues = {
		file_name: "",
		description: "",
		url: "",
		file_size: 0,
		server_type: "",
	}

	function showError(message: string) {
		setError(message)
		setTimeout(() => {
			setError("")
		}, 3000)
	}

	async function register(values: any) {
		try {
			setLoading(true)
			const response = await http.post("/v1/bi-floresta-files", values)
			if (response.status === 201) {
				toggle()
				if (onSuccess) onSuccess()
			} else if (response.data?.message) {
				showError(response.data.message)
			}
		} catch (error) {
			console.error(error)
			showError("Erro ao cadastrar arquivo")
		} finally {
			setLoading(false)
		}
	}

	return (
		<div className='inline-flex'>
			<Button
				severity='success'
				onClick={toggle}
				size='large'
				icon='pi pi-plus'
				className='px-4 py-2 flex items-center gap-2 font-semibold'
			>
				Novo Arquivo
			</Button>

			<Dialog
				header='Cadastrar arquivo BI'
				onHide={toggle}
				visible={modal}
				{...args}
			>
				<Formik
					initialValues={initialValues}
					validationSchema={Schema}
					onSubmit={register}
				>
					<Form className='flex flex-wrap space-y-6 p-10'>
						<FormikInput
							className='w-full'
							name='file_name'
							label='Nome do arquivo'
							placeholder='Digite o nome do arquivo'
						/>

						<FormikInput
							className='w-full'
							name='description'
							label='Descrição'
							placeholder='Digite a descrição'
						/>

						<FormikInput
							className='w-full'
							name='url'
							label='URL'
							placeholder='Digite a URL do arquivo'
						/>

						<div className='flex w-full space-x-10'>
							<FormikInput
								className='w-1/2'
								type='number'
								name='file_size'
								label='Tamanho (MB)'
								placeholder='Informe o tamanho do arquivo em MB'
								min={"0"}
								step={0.01}
							/>

							<FormikInput
								className='w-1/2'
								name='server_type'
								label='Servidor'
								placeholder='Digite o tipo de servidor (Ex: PowerBI, AWS)'
							/>
						</div>

						<FormButtons toggle={toggle} loading={loading} />

						{error && (
							<Message severity='error' className='w-full mt-4'>
								{error}
							</Message>
						)}
					</Form>
				</Formik>
			</Dialog>
		</div>
	)
}

export default ModalRegisterFile
