import { useEffect, useState } from "react"
import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column"
import { BreadCrumb } from "primereact/breadcrumb"
import { FilterMatchMode } from "primereact/api"
import { Form, Formik } from "formik"
import { useToast } from "../../components/Toast"
import Loading from "../../components/Loading"
import TablePagination from "../../components/TablePagination"
import { BiForestaFile, BiForestaFileMeta } from "./types/BIFlorestaFilesType"
import ModalRegisterFile from "./Register/Modal"
import ModalEditFile from "./Edit/Modal"
import ModalDeleteFile from "./Delete/Modal"
import http from "../../plugins/http"
import { Button } from "primereact/button"
import FormikInput from "../../components/FormikInput"
import FormikSelect from "../../components/FormikSelect"

interface FilterValue {
	value: string
	matchMode: FilterMatchMode
}

interface FiltersState {
	file_name: FilterValue
	server_type: FilterValue
	sort_by: string
	order: "asc" | "desc"
}

interface FetchFilesParams {
	page?: number
	perPage?: number
	filters?: FiltersState
}

const TableRowActions = ({
	file,
	update,
}: {
	file: BiForestaFile
	update: () => void
}) => {
	const toast = useToast()

	return (
		<div className='w-25 flex flex-row space-x-2'>
			<ModalEditFile
				file={file}
				className='w-1/2'
				onEdit={() => {
					toast.current?.show({
						severity: "success",
						summary: "Sucesso",
						detail: "Arquivo atualizado com sucesso",
					})
					update()
				}}
			/>
			<ModalDeleteFile
				file={file}
				className='w-1/2'
				onDelete={() => {
					toast.current?.show({
						severity: "success",
						summary: "Sucesso",
						detail: "Arquivo excluído com sucesso",
					})
					update()
				}}
			/>
		</div>
	)
}

function BiForestaFilesPage() {
	const toast = useToast()
	const [files, setFiles] = useState<BiForestaFile[]>([])
	const [meta, setMeta] = useState<BiForestaFileMeta>({} as BiForestaFileMeta)
	const [loading, setLoading] = useState<boolean>(false)
	const [currentPage, setCurrentPage] = useState<number>(1)
	const [filters, setFilters] = useState<FiltersState>({
		file_name: { value: "", matchMode: FilterMatchMode.CONTAINS },
		server_type: { value: "", matchMode: FilterMatchMode.EQUALS },
		sort_by: "created_at",
		order: "desc",
	})

	const initialFilterValues = {
		file_name: "",
		server_type: "",
		sort_by: "created_at",
		order: "desc",
	}

	async function fetchFiles(params: FetchFilesParams = {}) {
		const {
			page = currentPage,
			perPage = 20,
			filters: paramFilters = filters,
		} = params

		try {
			setLoading(true)
			const urlParams = new URLSearchParams()
			urlParams.append("page", page.toString())
			urlParams.append("per_page", perPage.toString())

			if (paramFilters.file_name.value) {
				urlParams.append("file_name", paramFilters.file_name.value)
			}
			if (paramFilters.server_type.value) {
				urlParams.append("server_type", paramFilters.server_type.value)
			}
			if (paramFilters.sort_by) {
				urlParams.append("sort_by", paramFilters.sort_by)
				urlParams.append("order", paramFilters.order)
			}

			const response = await http.get(
				`v1/bi-floresta-files?${urlParams.toString()}`,
			)
			setFiles(response.data.data)
			setMeta(response.data.meta)
			setCurrentPage(page)
		} catch (error) {
			console.error(error)
			toast.current?.show({
				severity: "error",
				summary: "Erro",
				detail: "Erro ao carregar arquivos",
			})
		} finally {
			setLoading(false)
		}
	}

	const handleFilterChange = (values: any) => {
		const newFilters = {
			file_name: {
				value: values.file_name,
				matchMode: FilterMatchMode.CONTAINS,
			},
			server_type: {
				value: values.server_type,
				matchMode: FilterMatchMode.EQUALS,
			},
			sort_by: values.sort_by,
			order: values.order,
		}
		setFilters(newFilters as FiltersState)
		fetchFiles({ filters: newFilters as FiltersState })
	}

	const resetFilters = () => {
		const defaultFilters: FiltersState = {
			file_name: { value: "", matchMode: FilterMatchMode.CONTAINS },
			server_type: { value: "", matchMode: FilterMatchMode.EQUALS },
			sort_by: "created_at",
			order: "desc",
		}
		setFilters(defaultFilters)
		fetchFiles({ filters: defaultFilters })
	}

	useEffect(() => {
		fetchFiles()
	}, [])

	const orderOptions: { value: string; label: string }[] = [
		{ label: "Decrescente", value: "desc" },
		{ label: "Crescente", value: "asc" },
	]

	const sortOptions: { value: string; label: string }[] = [
		{ label: "Data de criação", value: "created_at" },
		{ label: "Nome do arquivo", value: "file_name" },
		{ label: "Tamanho", value: "file_size" },
	]

	const fileSizeTemplate = (rowData: BiForestaFile) => (
		<div>{Number(rowData.file_size).toFixed(2)} MB</div>
	)

	const dateTemplate = (rowData: BiForestaFile) => (
		<div>
			{new Date(rowData.created_at).toLocaleDateString("pt-BR", {
				day: "2-digit",
				month: "2-digit",
				year: "numeric",
				hour: "2-digit",
				minute: "2-digit",
			})}
		</div>
	)

	const actionTemplate = (rowData: BiForestaFile) => (
		<TableRowActions file={rowData} update={fetchFiles} />
	)

	const items = [{ label: "BI da Floresta", url: "/bi-floresta-files" }]
	const home = { icon: "pi pi-home", url: "/home" }

	return (
		<div className='p-10'>
			<BreadCrumb model={items} home={home} className='mb-4' />

			<div className='flex flex-col gap-4'>
				<div className='flex justify-between items-center'>
					<h1 className='text-2xl font-bold'>Arquivos BI da Floresta</h1>
					<ModalRegisterFile
						onSuccess={() => {
							toast.current?.show({
								severity: "success",
								summary: "Sucesso",
								detail: "Arquivo cadastrado com sucesso",
							})
							fetchFiles()
						}}
					/>
				</div>

				<div className='bg-white p-4 rounded-lg shadow-sm'>
					<Formik
						initialValues={initialFilterValues}
						onSubmit={() => {}}
						enableReinitialize
					>
						{(formikProps) => (
							<Form className='flex flex-col lg:flex-row lg:justify-between lg:items-end gap-4 w-full'>
								<div className='flex flex-col lg:flex-row gap-4 flex-grow'>
									<div className='w-full lg:w-1/3'>
										<FormikInput
											label='Buscar por nome'
											name='file_name'
											className='w-full'
											placeholder='Buscar por nome'
											onChanged={(e) => {
												formikProps.handleChange(e)
												handleFilterChange({
													...formikProps.values,
													file_name: e.target.value,
												})
											}}
										/>
									</div>
									<div className='flex flex-col sm:flex-row gap-4 w-full lg:w-2/3'>
										<div className='w-full sm:w-1/3'>
											<FormikInput
												label='Filtrar por servidor'
												name='server_type'
												className='w-full'
												placeholder='Filtrar por servidor'
												onChanged={(e) => {
													formikProps.handleChange(e)
													handleFilterChange({
														...formikProps.values,
														server_type: e.target.value,
													})
												}}
											/>
										</div>

										<div className='w-full sm:w-1/3'>
											<FormikSelect
												label='Ordenar por'
												name='sort_by'
												className='w-full'
												placeholder='Ordenar por'
												options={sortOptions}
												onChange={(value) => {
													formikProps.setFieldValue("sort_by", value)
													handleFilterChange({
														...formikProps.values,
														sort_by: value,
													})
												}}
											/>
										</div>

										<div className='w-full sm:w-1/3'>
											<FormikSelect
												label='Ordem'
												name='order'
												className='w-full'
												placeholder='Ordem'
												options={orderOptions}
												onChange={(value) => {
													formikProps.setFieldValue("order", value)
													handleFilterChange({
														...formikProps.values,
														order: value,
													})
												}}
											/>
										</div>
									</div>
								</div>
								<div className='w-full lg:w-auto'>
									<Button
										label='Limpar Filtros'
										type='button'
										severity='secondary'
										icon='pi pi-refresh'
										onClick={() => {
											formikProps.resetForm()
											resetFilters()
										}}
										className='w-full lg:w-auto whitespace-nowrap'
									></Button>
								</div>
							</Form>
						)}
					</Formik>
				</div>

				{loading && (
					<div className='h-36 grid place-content-center p-20'>
						<Loading color='#212529' size='w-10' />
					</div>
				)}
				{!loading && files.length === 0 && (
					<div className='h-36 grid place-content-center p-20'>
						<p>Nenhum arquivo encontrado</p>
					</div>
				)}
				{!loading && files.length > 0 && (
					<div className='bg-white rounded-lg shadow-sm'>
						<DataTable
							value={files}
							showGridlines
							className='mt-4'
							emptyMessage='Nenhum arquivo encontrado'
						>
							<Column
								body={actionTemplate}
								header='Ações'
								className='text-center'
							/>
							<Column field='file_name' header='Nome do Arquivo' sortable />
							<Column field='description' header='Descrição' />
							<Column
								field='url'
								header='URL'
								body={(rowData) => (
									<a
										href={rowData.url}
										target='_blank'
										rel='noopener noreferrer'
										className='text-blue-600 hover:text-blue-800'
									>
										Acessar
									</a>
								)}
							/>
							<Column body={fileSizeTemplate} header='Tamanho' sortable />
							<Column field='server_type' header='Servidor' sortable />
							<Column body={dateTemplate} header='Data de Cadastro' sortable />
						</DataTable>

						<TablePagination
							meta={meta}
							onChange={(page) => fetchFiles({ page })}
						/>
					</div>
				)}
			</div>
		</div>
	)
}

export default BiForestaFilesPage
