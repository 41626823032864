import React, { useState } from "react"
import "primeicons/primeicons.css"
import "./styles.css"
import { Footer } from "../Footer"
import { useNavigate } from "react-router-dom"
import MenuCustom from "../MenuCustom/MenuCustom"

const Sidebar: React.FC = () => {
	const [selectedItem, setSelectedItem] = useState<string | null>(null)
	const navigate = useNavigate()

	const handleMenuItemClick = (label: string) => {
		setSelectedItem(label)
		navigate(label)
	}

	const openUrl = (url: string) => {
		window.open(url, "_blank")
	}

	let items = [
		// {
		//   label: 'Models',
		//   icon: 'pi pi-sitemap',
		// },
		// {
		//   label: 'Endpoints',
		//   icon: 'pi pi-sitemap',
		// },
		{
			label: "Início",
			icon: "pi pi-home",
			command: () => handleMenuItemClick("home"),
			className: selectedItem === "home" ? "bg-green-200" : "",
		},
		{
			label: "BI da Floresta",
			icon: "pi pi-globe",
			className: "select-none",
			items: [
				{
					label: "Arquivos",
					icon: "pi pi-file",
					command: () => handleMenuItemClick("bi-floresta-files"),
					className: selectedItem === "bi-floresta-files" ? "bg-green-200" : "",
				},
			],
		},
		{
			label: "Categorição de Dashboards",
			icon: "pi pi-chart-pie",
			command: () => handleMenuItemClick("data-categorization"),
			className: selectedItem === "data-categorization" ? "bg-green-200" : "",
		},
		{
			label: "Usuários",
			icon: "pi pi-users",
			command: () => handleMenuItemClick("users"),
			className: selectedItem === "users" ? "bg-green-200" : "",
		},
		{
			label: "Armazéns",
			icon: "pi pi-search-plus",
			command: () => handleMenuItemClick("warehouses"),
			className: selectedItem === "warehouses" ? "bg-green-200" : "",
		},
		{
			label: "Produtos",
			icon: "pi pi-tag",
			command: () => handleMenuItemClick("products"),
			className: selectedItem === "products" ? "bg-green-200" : "",
		},
		{
			label: "Transportadoras",
			icon: "pi pi-send",
			command: () => handleMenuItemClick("carriers"),
			className: selectedItem === "carriers" ? "bg-green-200" : "",
		},
		{
			label: "Cotações de Frete",
			icon: "pi pi-truck",
			command: () => handleMenuItemClick("freights"),
			className: selectedItem === "freights" ? "bg-green-200" : "",
		},
		{
			label: "Edição de dados gerais",
			icon: "pi pi-pen-to-square",
			command: () => handleMenuItemClick("parameterization"),
			className: selectedItem === "parameterization" ? "bg-green-200" : "",
		},
		{
			label: "Painel de relatórios",
			icon: "pi pi-file-excel",
			className: selectedItem === "reports" ? "bg-green-200" : "",
			items: [
				{
					label: "Relatório Geral",
					icon: "pi pi-th-large",
					command: () => handleMenuItemClick("reports/general"),
					className: selectedItem === "general" ? "bg-green-200" : "",
				},
				{
					label: "Relatório de Vendas",
					icon: "pi pi-shopping-cart",
					command: () => handleMenuItemClick("reports/sales"),
					className: selectedItem === "sales" ? "bg-green-200" : "",
				},
				{
					label: "Relatório de Usuário",
					icon: "pi pi-users",
					command: () => handleMenuItemClick("reports/users"),
					className: selectedItem === "users" ? "bg-green-200" : "",
				},
				{
					label: "Relatório de Produtos",
					icon: "pi pi pi-box",
					command: () => handleMenuItemClick("reports/products"),
					className: selectedItem === "products" ? "bg-green-200" : "",
				},
				{
					label: "Relatório de Demandas",
					icon: "pi pi-chart-bar",
					command: () => handleMenuItemClick("reports/demands"),
					className: selectedItem === "demands" ? "bg-green-200" : "",
				},
			],
		},
		{
			label: "Clarity Microsoft",
			icon: "pi pi-chart-line",
			command: () =>
				openUrl(
					"https://clarity.microsoft.com/projects/view/mql4as1049/dashboard?date=Last%203%20days",
				),
			className: selectedItem === "statistics" ? "bg-green-200" : "",
		},
		{
			label: "Logs do sistema",
			icon: "pi pi-list",
			command: () => handleMenuItemClick("logs"),
			className: selectedItem === "logs" ? "bg-green-200" : "",
		},
		{
			label: "Sair",
			icon: "pi pi-sign-out",
			command: () => {
				localStorage.clear()
				window.location.href = "/login"
			},
		},
	]

	return (
		<div className='flex flex-col h-full w-16 sm:w-64 bg-white shadow-lg'>
			<div className='w-full h-24 bg-gradient-to-r from-green-500 to-green-600 flex flex-col items-center justify-center shadow-md'>
				<p className='text-xl font-bold text-white'>BIOCONEX</p>
				<p className='text-green-100 font-medium'>Parametrização</p>
				<p className='text-green-100 text-xs mt-1'>v1.3.0</p>
			</div>
			<div className='flex-grow overflow-y-auto scrollbar-thin scrollbar-thumb-green-200 scrollbar-track-transparent'>
				<MenuCustom model={items} className='w-full bg-white py-2' />
			</div>
			<div className='mt-auto border-t border-gray-100'>
				<Footer />
			</div>
		</div>
	)
}

export default Sidebar
