import { memo, useCallback, useEffect, useState } from "react"
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown"
import http from "../../../plugins/http"
import TablePagination from "../../../components/TablePagination"
import Loading from "../../../components/Loading"
import { Message } from "primereact/message"
import { Accordion, AccordionTab } from "primereact/accordion"
import { Badge } from "primereact/badge"
import { Close } from "@styled-icons/evaicons-solid"
import { useToast } from "../../../components/Toast"
import { InputText } from "primereact/inputtext"
import { Button } from "primereact/button"
import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column"
import { MaskCPForCNPJ } from "../../../plugins/mask"
import { BreadCrumb } from "primereact/breadcrumb"
import ModalUserDetail from "./Detail/ModalUserDetail"
import { fetchRoles } from "../../../api/roles"
import { fetchStates } from "../../../api/states"
import { fetchCities } from "../../../api/cities"
import {
	FilterState,
	FilterInputProps,
	IgnorePatternItemProps,
	AdvancedFiltersProps,
	User,
	MetaData,
} from "./interfaces"

const FilterInput = memo(
	({ value, onChange, onKeyDown, onAdd }: FilterInputProps) => (
		<div className='flex flex-col sm:flex-row gap-2'>
			<InputText
				className='flex-1'
				value={value}
				onChange={onChange}
				onKeyDown={onKeyDown}
				placeholder='Digite o padrão a ser ignorado'
			/>
			<Button onClick={onAdd} severity='success' className='w-full sm:w-auto'>
				<span className='px-4'>Adicionar</span>
			</Button>
		</div>
	),
)

const IgnorePatternItem = memo(({ name, onRemove }: IgnorePatternItemProps) => (
	<div className='flex items-center bg-gray-100 rounded-lg px-2 py-1'>
		<Badge className='mr-2 bg-gray-100 !text-blue-500 !text-sm' value={name} />
		<Close
			className='cursor-pointer  hover:text-red-500'
			onClick={() => onRemove(name)}
			size='16'
		/>
	</div>
))

const AdvancedFilters = memo(
	({
		filters,
		advFilterName,
		setAdvFilterName,
		addNameToIgnore,
		removeNameToIgnore,
	}: AdvancedFiltersProps) => (
		<Accordion className='mt-4'>
			<AccordionTab header='Filtros avançados'>
				<div className='space-y-4'>
					<div>
						<p className='text-sm font-bold'>Padrão a ser ignorado</p>
						<p className='text-sm text-gray-600 mb-2'>
							Se um usuário conter no nome ou email uma dos padrões cadastrados
							aqui, eles serão ignorados do relatório.
						</p>
					</div>
					<FilterInput
						value={advFilterName}
						onChange={(e) => setAdvFilterName(e.target.value)}
						onKeyDown={(e) => {
							if (e.key === "Enter") {
								addNameToIgnore()
							}
						}}
						onAdd={addNameToIgnore}
					/>

					<div className='flex flex-wrap gap-2 mt-2'>
						{filters.wordsToIgnore.map((name) => (
							<IgnorePatternItem
								key={name}
								name={name}
								onRemove={removeNameToIgnore}
							/>
						))}
					</div>
				</div>
			</AccordionTab>
		</Accordion>
	),
)

const UsersTable: React.FC = () => {
	const today = new Date()
	const prevMonth = new Date()
	prevMonth.setMonth(today.getMonth() - 1)
	const toast = useToast()
	const [users, setUsers] = useState<User[]>([])
	const [meta, setMeta] = useState<MetaData>({
		total: 0,
		per_page: 100,
		current_page: 1,
		last_page: 1,
		first_page: 1,
		first_page_url: "/?page=1",
		last_page_url: "/?page=1",
		next_page_url: null,
		previous_page_url: null,
	})
	const [loading, setLoading] = useState(false)
	const [advFilterName, setAdvFilterName] = useState("")
	const [roles, setRoles] = useState<Array<{ label: string; value: number }>>(
		[],
	)
	const [states, setStates] = useState<Array<{ label: string; value: number }>>(
		[],
	)
	const [cities, setCities] = useState<Array<{ label: string; value: number }>>(
		[],
	)
	const [filters, setFilters] = useState<FilterState>({
		status: 99,
		role: 0,
		stateId: null,
		cityId: null,
		dateInitial: prevMonth.toISOString().split("T")[0],
		dateFinal: new Date().toISOString().split("T")[0],
		page: 1,
		perPage: 100,
		wordsToIgnore: ["@certi", "@ica", "Adamastor-", "teste"],
	})

	const userProfiles = [
		{ public_name: "Comprador e vendedor", value: 0 },
		{ public_name: "Vendedor", value: 1 },
		{ public_name: "Vendedor (Sem perfil integrador)", value: 11 },
		{ public_name: "Comprador", value: 2 },
		{ public_name: "Visitantes (CPF)", value: 8 },
		{ public_name: "Outros (Financiador, Startup, Outros, etc)", value: 9 },
		{ public_name: "Todos", value: 10 },
		{ public_name: "Administrativo", value: 3 },
		{ public_name: "Quem já comprou", value: 4 },
		{ public_name: "Quem já vendeu", value: 5 },
		{ public_name: "Quem tem produto anunciado", value: 6 },
		{ public_name: "Quem tem demanda anunciada", value: 7 },
	]

	const userSituation = [
		{ public_name: "Todos", value: 99 },
		{ public_name: "Ativados", value: 1 },
		{ public_name: "Desativados", value: 0 },
	]

	const profilesFlags = [
		{ name: "producer", label: "Vendedor" },
		{ name: "buyer", label: "Comprador" },
		{ name: "government", label: "Governo" },
		{ name: "funder", label: "Financiador" },
		{ name: "ict", label: "ICT" },
		{ name: "investor", label: "Investidor" },
		{ name: "startUp", label: "StartUp" },
		{ name: "logisticOperator", label: "Operador Logístico" },
		{ name: "other", label: "Outro" },
	]

	const fetchUsers = useCallback(async (filter: FilterState) => {
		try {
			setLoading(true)
			const response = await http.post<{ data: User[]; meta: MetaData }>(
				"v1/reports/users",
				filter,
			)
			if (response.status === 200) {
				setUsers(response.data.data)
				setMeta(response.data.meta)
			}
		} catch (error) {
			console.error("Error fetching users:", error)
		} finally {
			setLoading(false)
		}
	}, [])

	const handleStateChange = useCallback(
		async (e: DropdownChangeEvent) => {
			const stateId = e.value
			setFilters((prev) => ({ ...prev, stateId, cityId: null }))
			setCities([])

			if (stateId) {
				setLoading(true)
				try {
					const response = await fetchCities({ id_state: stateId })
					setCities(
						response.data.map((city: any) => ({
							label: city.name,
							value: city.id,
						})),
					)
				} catch (error) {
					console.error("Error fetching cities:", error)
				} finally {
					setLoading(false)
				}
			}

			fetchUsers({ ...filters, stateId, cityId: null })
		},
		[filters, fetchUsers],
	)

	const handleFilterChange = useCallback(
		(e: DropdownChangeEvent | React.ChangeEvent<HTMLInputElement>) => {
			const { name, value } = e.target
			setFilters((prev) => ({ ...prev, [name]: value }))
			const timeoutId = setTimeout(() => {
				fetchUsers({ ...filters, [name]: value })
			}, 300)
			return () => clearTimeout(timeoutId)
		},
		[filters, fetchUsers],
	)

	const addNameToIgnore = useCallback(() => {
		if (!advFilterName) return

		if (filters.wordsToIgnore.includes(advFilterName)) {
			toast.current?.show({
				severity: "warn",
				summary: "Atenção",
				detail: "Esse nome já está na lista de padrões a serem ignorados",
			})
			return
		}

		setFilters((prev) => ({
			...prev,
			wordsToIgnore: [...prev.wordsToIgnore, advFilterName],
		}))
		setAdvFilterName("")

		fetchUsers({
			...filters,
			wordsToIgnore: [...filters.wordsToIgnore, advFilterName],
		})
	}, [advFilterName, filters, fetchUsers, toast])

	const removeNameToIgnore = useCallback(
		(name: string) => {
			setFilters((prev) => ({
				...prev,
				wordsToIgnore: prev.wordsToIgnore.filter((n) => n !== name),
			}))
			fetchUsers({
				...filters,
				wordsToIgnore: filters.wordsToIgnore.filter((n) => n !== name),
			})
		},
		[filters, fetchUsers],
	)

	useEffect(() => {
		fetchUsers(filters)
		Promise.all([fetchRoles(), fetchStates()])
			.then(([rolesResponse, statesResponse]) => {
				setRoles(
					rolesResponse.data.map((x: any) => ({
						label: x.public_name,
						value: x.id,
					})),
				)

				setStates(
					statesResponse.data.map((state: any) => ({
						label: state.name,
						value: state.id,
					})),
				)
			})
			.catch((error) => {
				console.error("Error fetching initial data:", error)
			})
	}, [])

	const changePage = (page: number) => {
		setFilters({ ...filters, page })
		fetchUsers({ ...filters, page })
	}

	const generateReport = async () => {
		http
			.post("v1/reports/export-users", filters, {
				responseType: "blob",
			})
			.then((response) => {
				const blob = new Blob([response.data], {
					type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
				})
				const url = window.URL.createObjectURL(blob)
				const a = document.createElement("a")
				a.href = url
				a.download = "relatorio-usuarios.xlsx" // Nome do arquivo
				document.body.appendChild(a)
				a.click()
				a.remove()
				window.URL.revokeObjectURL(url)
				toast.current?.show({
					severity: "success",
					summary: "Sucesso",
					detail: "Relatório gerado com sucesso",
				})
			})
			.catch((error) => {
				console.log(error)
				toast.current?.show({
					severity: "error",
					summary: "Erro",
					detail: "Erro ao gerar relatório",
				})
			})
	}

	const statusTemplate = (rowData: any) => {
		return (
			<div className='text-center'>
				{rowData.status === 1 ? (
					<p className='text-[#28A745]'>Ativo</p>
				) : (
					<p className='text-red-500'>Desativado</p>
				)}
			</div>
		)
	}

	const profileTemplate = (rowData: any) => {
		for (const flag in rowData.flags) {
			if (rowData.flags[flag]) {
				const profile = profilesFlags.find((p) => p.name === flag)
				return (
					<div className='text-center'>
						<Badge className='px-2' value={profile?.label} severity='success' />
					</div>
				)
			}
		}
		return (
			<div className='text-center'>
				<Badge className='px-2' value='-' severity='info' />
			</div>
		)
	}

	const dateTemplate = (rowData: any) => {
		return (
			<div className='text-center'>
				{new Date(rowData.created_at).toLocaleDateString()}
			</div>
		)
	}

	const rowActions = (rowData: any) => {
		return (
			<div className='flex justify-center items-center'>
				<ModalUserDetail
					user={rowData}
					onEdit={() => {}}
					roles={roles}
					states={states}
				/>
			</div>
		)
	}

	const items = [
		{ label: "Relatórios", url: "/reports" },
		{ label: "Relatório de Usuários" },
	]
	const home = { icon: "pi pi-home", url: "/home" }

	return (
		<div className='w-full p-4 md:p-6 lg:p-10'>
			<BreadCrumb model={items} home={home} className='mb-4' />

			<div className='flex flex-col space-y-2'>
				<div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-6 gap-2'>
					<div className='flex flex-col'>
						<label className='text-sm font-bold mb-1'>Perfil</label>
						<Dropdown
							name='role'
							value={filters.role}
							options={userProfiles}
							onChange={handleFilterChange}
							optionLabel='public_name'
							className='w-full'
							placeholder='Selecione o perfil'
						/>
					</div>

					<div className='flex flex-col align-center'>
						<label className='text-sm font-bold mb-1'>Estado</label>
						<Dropdown
							name='stateId'
							value={filters.stateId}
							options={states}
							onChange={handleStateChange}
							optionLabel='label'
							className='w-full'
							placeholder='Selecione o estado'
						/>
					</div>

					<div className='flex flex-col'>
						<label className='text-sm font-bold mb-1'>Cidade</label>
						<Dropdown
							name='cityId'
							value={filters.cityId}
							options={cities}
							onChange={handleFilterChange}
							optionLabel='label'
							className='w-full'
							placeholder='Selecione a cidade'
							disabled={!filters.stateId}
						/>
					</div>

					<div className='flex flex-col'>
						<label className='text-sm font-bold mb-1'>Situação</label>
						<Dropdown
							name='status'
							value={filters.status}
							options={userSituation}
							onChange={handleFilterChange}
							optionLabel='public_name'
							placeholder='Selecione a situação'
							className='w-full'
						/>
					</div>

					<div className='flex flex-col'>
						<label className='text-sm font-bold mb-1'>Período</label>
						<div className='grid grid-cols-2 gap-2'>
							<InputText
								value={filters.dateInitial}
								name='dateInitial'
								onChange={handleFilterChange}
								type='date'
								className='w-full'
							/>
							<InputText
								value={filters.dateFinal}
								name='dateFinal'
								onChange={handleFilterChange}
								type='date'
								className='w-full'
							/>
						</div>
					</div>

					<div className='flex flex-col justify-end'>
						<div className='flex gap-2'>
							<Button
								disabled={loading}
								onClick={() => fetchUsers(filters)}
								severity='success'
								className='w-full'
							>
								Filtrar
							</Button>
							<Button
								disabled={loading}
								onClick={() => generateReport()}
								severity='success'
								className='w-full'
							>
								Exportar
							</Button>
						</div>
					</div>
				</div>
			</div>

			<Message
				severity='warn'
				className='w-full mt-4'
				text='Para resultados mais confiáveis, alguns filtros foram aplicados automaticamente. Você pode desativá-los nos filtros avançados.'
			/>
			<AdvancedFilters
				filters={filters}
				advFilterName={advFilterName}
				setAdvFilterName={setAdvFilterName}
				addNameToIgnore={addNameToIgnore}
				removeNameToIgnore={removeNameToIgnore}
			/>
			<div className='mt-6'>
				{loading && (
					<div className='w-full h-36 grid place-content-center'>
						<Loading color='#212529' size='w-10' />
					</div>
				)}

				{!loading && users.length === 0 && (
					<div className='w-full h-36 grid place-content-center'>
						<p className='text-gray-500'>
							Nenhum usuário encontrado com os filtros aplicados
						</p>
					</div>
				)}

				{!loading && users.length > 0 && (
					<div className='space-y-4'>
						<Message
							severity='info'
							className='w-full space-y-2'
							text={`Total de registros: ${users.length}`}
						/>

						<div className='overflow-x-auto'>
							<DataTable
								value={users}
								showGridlines
								className='w-full font-roboto text-black'
							>
								<Column body={rowActions} header='Ações' />
								<Column field='name' header='Nome' />
								<Column
									body={(item) => <span>{item?.integration?.name}</span>}
									header='Razão Social'
								/>
								<Column field='cpf_or_cnpj' header='CPF/CNPJ (Cadastro)' />
								<Column
									body={(item) => (
										<span>
											{MaskCPForCNPJ({ value: item?.integration?.cpf_or_cnpj })}
										</span>
									)}
									header='CNPJ (Venda)'
								/>
								<Column field='email' header='Email' />
								<Column field='phone' header='Celular' />
								<Column
									body={(row) => (
										<span>
											{row?.city?.name}/{row?.state?.short_name}
										</span>
									)}
									header='Cidade'
								/>
								<Column body={profileTemplate} header='Perfil de Cadastro' />
								<Column body={statusTemplate} header='Situação' />
								<Column body={dateTemplate} header='Data de Cadastro' />
							</DataTable>
						</div>

						{meta?.current_page && (
							<TablePagination
								meta={meta}
								onChange={(page) => changePage(page)}
							/>
						)}
					</div>
				)}
			</div>
		</div>
	)
}

export default UsersTable
