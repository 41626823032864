import { Form, Formik, FormikProps, useFormikContext } from "formik";
import { useRef, useState } from "react";
import FormikInput from "../../../components/FormikInput";
import http from "../../../plugins/http";
import { Block, CheckCircle } from "@styled-icons/boxicons-regular";
import Loading from "../../../components/Loading";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Message } from "primereact/message";
import Schema from "./validation";
import FormikTextArea from "../../../components/FormikInputTextArea";

function ModalEnableAndDisableUser({
  user,
  className = "",
  changeStatus,
}: {
  user: any;
  className?: string;
  changeStatus: (user: any, form: any) => void;
}) {
  const [modal, setModal] = useState(false);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [schema, setSchema] = useState<any>(Schema);
  const formikRef = useRef<FormikProps<typeof initialValues>>(null);
  const toggle = () => {
    setModal(prevModal => !prevModal);
  };

  let initialValues = {
    justification: "",
  };
  function FormButtons() {
    const { submitForm } = useFormikContext();
    return (
      <div className="w-full flex justify-end p-4 gap-x-4">
        <Button onClick={toggle} disabled={loading} severity="danger" outlined>
          Cancelar
        </Button>
        <Button type="submit" severity="success" onClick={submitForm} loading={loading}>
          {user.status === 1 ? "Desativar" : "Ativar"}
        </Button>
      </div>
    );
  }

  return (
    <div className="inline-flex">
      {user.status === 1 ? (
        <Block onClick={toggle} size={25} color="#F91B1B" className="cursor-pointer" />
      ) : (
        <CheckCircle onClick={toggle} size={26} color="#28A745" className="cursor-pointer" />
      )}
      
      <Dialog header={user.status == 1 ? 'Desativar usuário' : 'Ativar usuário'} visible={modal} onHide={toggle} className={className}>
        <Formik
          innerRef={formikRef}
          initialValues={initialValues}
          validationSchema={schema}
          onSubmit={(values, actions) => {
            changeStatus(user, values);
          }}
          key={"form-change-user-status"}
        >
          <>
            <Form className="flex flex-wrap p-2">
              <FormikTextArea className="w-full" label="Justificativa" name="justification" rows={5} />
            </Form>
            <FormButtons />
            {error !== "" && (
              <Message className="w-full mt-4" color="danger">
                {error}
              </Message>
            )}
          </>
        </Formik>
      </Dialog>
    </div>
  );
}

export default ModalEnableAndDisableUser;
